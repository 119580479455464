<template>
    <div id="edgeEndMapSetting"  v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <my-header-title> 设备与终端信号关联管理 </my-header-title>
        <div class="tool">
            <a-button type="primary"  @click="reload"> {{$t('energy.enedata006')}} </a-button>
        </div>
        <div class="main">
            <div class="box" v-for="(item,index) in equipmentMapInfos" :key="'equipt'+index" >
                <div class="head">
                    <div class="head1">设备/信号</div>
                    <div class="head1">映射设备对象</div>
                </div>
                <div class="item">
                    <div class="item1">{{item.name}}</div>
                    <div>
                        <a-select v-model="item.tagPoint"  style="width:300px;" @change="() => equipmentMapChange(item)">
                            <a-select-option  v-for="(val,i) in equipmentList" :key="i" 
                            :value="(val.type+ ';' +val.id +';'+val.device)"
                            :title="(val.device + '--' + val.type +  '--' + val.name)" 
                            :disabled="getSelExit(val.type+ ';' +val.id)">
                             {{ val.device + '--' + val.type +  '--' + val.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div  class="item" v-for="(signal,idx) in item.children" :key="'signal'+idx">
                    <div class="item2">{{signal.name}}</div>
                    <div>
                        <a-select v-model="signal.tagPoint"  style="width:300px;">
                            <a-select-option  v-for="(val,i) in item.mapValues" :key="i" :value="val.value" :disabled="checkExit(val.value,item.children)">
                                {{val.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="tool">
            <a-button type="primary"   @click="save"> 设置 </a-button>
        </div>
    </div>    
</template>
<script>
import {getEdgeEquipmentMapInfo,getEdgeEquipmentList,saveEdgeEquipmentMapInfo} from "../../../api/end.js";
const terminal = {
    TERMINAL_STATUS:[
        {id:1,value:"averageCpuLoad",name:"平均cpu负载"},
        {id:2,value:"averageGpuLoad",name:"平均gpu负载"},
        {id:3,value:"memUsage",name:"内存使用率"},
        {id:4,value:"diskUsage",name:"磁盘使用率"},
        {id:5,value:"diskIO",name:"磁盘IO"},
        {id:6,value:"cpuTemp",name:"cpu温度"},
        {id:7,value:"npuTemp",name:"npu温度"},
        // {id:8,value:"status",name:"运行状态"}
    ],
    TERMINAL_SIGNAL:[
        {id:1,value:"manCount",name:"人数"},
        {id:2,value:"eBike",name:"电动车数量"},
        {id:3,value:"wheelChair",name:"轮椅数量"},
        {id:4,value:"pet",name:"宠物数量"},
        {id:5,value:"cross",name:"跨界信号"},
        {id:6,value:"crowdRate",name:"人群比率"},
        {id:7,value:"occupyRate",name:"占有率"},
        {id:8,value:"eventEn",name:"区域事件信号"},
        {id:9,value:"visibleStatus",name:"可视化状态"},
        {id:10,value:"direction",name:"电梯状态"},
        {id:11,value:"outCount",name:"离开电梯人数"},
        {id:12,value:"inCount",name:"进入电梯人数"},
        {id:13,value:"waitingWheelChair",name:"候梯轮椅数量"},
        {id:14,value:"waitingPeople",name:"候梯人数"}
    ]
}
import headerTitle from "../../../components/Title/headerTitle";
export default {
    data() {
        return {
            loading: false,
            equipmentMapInfos:[],
            equipmentList:[],
            seledList:[],
        };
    },
    components: {
        'my-header-title':headerTitle,
    },
    mounted(){
        this.reload();
    },
    methods: {
        save(){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId: 0,
                equipmentList:this.equipmentList,
                values:this.equipmentMapInfos,
            };
            console.log("Save Params",params);
            this.$confirm({
                    title: "确定要进行设定内容注册吗?",
                    // title:this.$t("energy.enedata184"),
                    centered: true,
                    onOk: () => {
                        this.loading = true;
                        saveEdgeEquipmentMapInfo(params) .then((res) => {
                            console.log("Save EdgeEquipmentMapInfo res",res);
                            this.loading = false;
                            if (res.errorCode == "00" || res.errorCode == "03") {
                                this.$message.success(res.msg);
                                this.reload();
                            } else {
                                this.$message.error(res.msg);
                            }          
                        }).catch((err) => {
                            console.log(err);
                            this.loading = false;
                        });
                    }
                });
        },
        checkExit(value,list){
            for (let i = 0; i < list.length; i++) {
                let element = list[i];
                if(value !='' && element.tagPoint === value){
                    return true;
                }
            }
            return false;
        },
        equipmentMapChange(item){
            console.log("equipmentMapChange",item);
            let val = item.tagPoint;
            let vals = val.split(';');
            if(vals[0]==='EDGE'){
                item.mapValues = terminal['TERMINAL_STATUS'];
            }else{
                item.mapValues = terminal['TERMINAL_SIGNAL'];
            }
            let bol = item.exType?item.exType===vals[0]:false;
            if(!bol){
                item.exType = vals[0];
                item.children.forEach(element => {
                    element.tagPoint = '';
                });
            }
            this.seledList = this.getSelValues();
        },
        initEquipmentMap(item){
            console.log("initEquipmentMap",item);
            let val = item.tagPoint;
            let vals = val.split(';');
            if(vals[0]==='EDGE'){
                item.mapValues = terminal['TERMINAL_STATUS'];
            }else{
                item.mapValues = terminal['TERMINAL_SIGNAL'];
            }
            // let bol = item.exType?item.exType===vals[0]:false;
            // if(!bol){
            //     item.children.forEach(element => {
            //         element.tagPoint = '';
            //     });
            // }
            this.seledList = this.getSelValues();
        },
        getSelValues(){
            let arr = [];
            this.equipmentMapInfos.forEach(element => {
                if( element.tagPoint &&  element.tagPoint !=''){
                    arr.push( element.tagPoint );
                }
            });
            return arr;
        },
        getSelExit(value){
            for (let i = 0; i < this.seledList.length; i++) {
                let element = this.seledList[i];
                if(element === value){
                    return true;
                }
            }
            return false;
        },
        reload(){
            this.getEquipmentMapInfo();
            this.getEquipmentList();
        },
        getEquipmentMapInfo(){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId: 0
            };
            console.log("GetEquipmentMapInfo Params",params);
            this.loading = true;
            getEdgeEquipmentMapInfo(params)
            .then((res) => {
                console.log("GetEquipmentMapInfo Result",res);
                if(res.errorCode == '00'){
                    const {baseValues} = res.data;;
                    this.equipmentMapInfos = baseValues;
                    this.seledList = this.getSelValues();
                    this.equipmentMapInfos.forEach(element => {
                        this.initEquipmentMap(element);
                        // element.mapValues = terminal['TERMINAL_SIGNAL'];
                    });
                } 
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getEquipmentList(){
            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId: 0
            };
            console.log("GetEquipmentList Params",params);
            this.loading = true;
            getEdgeEquipmentList(params)
            .then((res) => {
                console.log("GetEquipmentList Result",res);
                if(res.errorCode == '00'){
                    const {data} = res;
                    this.equipmentList= data;
                } 
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
    }
}
</script>
<style scoped>
    #edgeEndMapSetting{
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
    }
    .head{
        display: flex;
        justify-content: space-between;
        padding:8px 5px;
        font-size: 14px;
        background-color: #e8e8e8;
    }
    .item{
        border-top: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        padding:3px;
    }
    .item1{
        line-height: 30px;
    }
    .item2{
        line-height: 30px;
        text-indent: 20px;
    }
    .box{
        width:40%;
        float:left;
        margin-right: 10px;
        border: 1px solid #e8e8e8;
    }
    .tool{
         padding:3px;
         text-align: right;
    }
    .main{
        width: 100%;
        height: calc(100% - 145px);
        overflow: auto;
        /* border: 1px solid #cccccc; */
    }
    .main::after{
        clear: both;
    }
</style>